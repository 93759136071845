import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import {
  ClearButton,
  EditCardContainer,
  EditInput,
  EditLabel,
  ErrorMessage,
  InputContainer
} from './styled';

import { useCardContext } from '../../../context/CardContext';
import { Card } from '../../../globalTypes';
import clear from '../../../images/clear.svg';

interface EditCardProps {
  isDesktop?: boolean;
  card: Card;
  close: () => void;
}

const EditCard: FC<EditCardProps> = ({ isDesktop, card, close }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [cardName, setCardName] = useState(card?.name);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const trimmedValue = value.trim(); 
    setCardName(value);
    if (trimmedValue.length === 0) {
      setErrorMessage(t('error_min_length'));
      return;
    }
    if (trimmedValue.length > 14) {
      setErrorMessage(t('error_max_length'));
      return;
    }
    setErrorMessage(''); 
  };

  const { updateCard } = useCardContext();

  useEffect(() => {
    inputRef.current?.focus();
  }, [card]);

  const handleClear = () => {
    setErrorMessage('');
    setCardName('');
    inputRef.current?.focus();
  };

  const handleSave = () => {
    if (card) {
      updateCard(card.id, { name: cardName.trim() }, close);
    }
  };

  return (
    <EditCardContainer isDesktop={isDesktop}>
      <InputContainer>
        <EditLabel htmlFor="card-name">{t('card_name')}</EditLabel>
        <EditInput
          ref={inputRef}
          autoFocus
          type="search"
          id="card-name"
          name="card-name"
          value={cardName}
          onChange={handleChange}
          isError={!!errorMessage}
          autoComplete="off"

        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ClearButton src={clear} alt="clear" onClick={handleClear} />
      </InputContainer>
      <Button onClick={handleSave} disabled={!!errorMessage || !cardName}>
        {t('save')}
      </Button>
    </EditCardContainer>
  );
};

export default EditCard;
